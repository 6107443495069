html {
    height: 100vh;
}
body {
    font-family: $f-sans-serif;
    background: $body-bg;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;

    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1000px;
}
#root {
    height: 100%;
    > * {
        height: 100%;
        display: flex;
        flex-direction: column;
        > * {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}
img {
    width: 100%;
    height: auto;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1em;
}
p {
    margin-bottom: 1em;
    @include flush-last-child;
}
input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}
input[type=radio] {
    background-image: url('/template/images/tmp/radio.png');
    display: block;
    width: 20px;
    height: 20px;
    appearance: none;
    background-size: cover;
    position: relative;
}
input[type=radio]:checked:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-image: url('/template/images/tmp/radio.png');
    background-size: cover;
    filter: brightness(50%);
    border-radius: 10px;
    top: 5px;
    left: 5px;
}
button, .button {
    -webkit-appearance: button;
    border: none;
    color: white;
    text-shadow: 0 0 5px black;
    background-image: url('/template/images/tmp/plank10.png');
    background-size: 100% 100%;
    background-position: center;
    border-radius: 10px;
    font-size: 24px;
    padding: 10px 15px;
    cursor: pointer;
    &:disabled {
        cursor: auto;
    }
}
svg {
    width: 100%;
    height: 100%;
}
dl.inline-flex {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 300px;      /* set the container width*/
    overflow: visible;
}
dl.inline-flex dt {
    flex: 0 0 25%;
    text-overflow: ellipsis;
    overflow: hidden;
}
dl.inline-flex dd {
    flex:0 0 75%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
}
