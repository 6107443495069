.achievements__subtitle {
    text-align: center;
    font-size: 24px;
}
.achievements__title {
    text-align: center;
    text-decoration: underline;
}

.grid--achievements {
    justify-content: center;
    align-items: flex-start;
    max-width: 400px;
    margin: 0 auto;
}
.achievement {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
}
    .achievement__image {
        margin-bottom: 10px;
    }
    .achievement--locked {
        img {
            filter: brightness(0.3);
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            aspect-ratio: 1;
            height: auto;
            scale: 0.5;
            filter: brightness(0.5);
        }
    }
