html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
/*	font-size: 100%;*/
	font-family: inherit;
	vertical-align: baseline;
}
body, html {
	overflow-x: hidden;
}
body {
	line-height: 1;
}
button {
	font-family: inherit;
}
a {
    text-decoration: none;
}
a:active, input:active {
	outline: none;
}
a:focus, input:focus {
	-moz-outline-style: none;
}
a {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
}
/*a:active, a:focus, input[type="button"] {
outline: 0;
}*/
ol, ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
}
strong, b {
	font-weight: bold;
}
em, i {
	font-style: italic;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}
progress {
	appearance: none;
	width: auto;
	height: auto;
}
