.shop-overlay__gold {
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    font-size: 28px;
    font-weight: 700;
    svg {
        width: 50px;
        height: 50px;
    }
}

.shop-general-wares-description {
    text-align: center;
    font-size: 24px;
}

.shop-general-wares {
    display: flex;
    justify-content: center;
}
    .shop-general-wares__description {
        font-size: 28px;
    }

.shop-general-ware {
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    aspect-ratio: 3/5;
    display: flex;
    background: black;
    width: 200px;
    position: relative;
    overflow: hidden;
}
    .shop-general-ware--heal {
        background: darkgreen;
    }
    .shop-general-ware--increase-max-hp {
        background: darkblue;
    }
    .shop-general-ware--delete {
        background: darkred;
    }
    .shop-general-ware--upgrade {
        background: darkgoldenrod;
    }

    .shop-general-ware--sold {
        cursor: initial;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(255,255,255,0.9);
        }
    }


.general-ware__description {
    color: white;
    font-size: 48px;
    text-align: center;
    align-self: center;
    margin: 0;
}
.general-ware__background-icon {
    position: absolute;
    opacity: 0.3;
    svg {
        width: 100%;
        height: 100%;
    }
}
    .general-ware__background-icon--first {
        left: -40%;
        top: -10%;
    }
    .general-ware__background-icon--second {
        left: 50%;
        top: 70%;
    }

.shop-discount-original-price {
    color: red;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background: red;
        top: 54%;
        left: 0;
    }
}
