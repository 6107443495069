.stats {
    display: flex;
    justify-content: center;
}

.stats__list {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 20px;
    @include flush-last-child;
    dt {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-bottom: 15px;
        &:after {
            border-bottom: 2px dotted #787878;
            content: "";
            margin: 0 10px;
            padding: 0 10px;
        }
    }
}
