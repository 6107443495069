
.confirm {
    height: 100px;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
    .confirm__text {
        margin-bottom: 20px;
    }
