.cards {
    padding: 20px 0 0 10px;
    display: flex;
    flex-wrap: wrap;
}
    .cards--shop {
        display: grid;
        overflow-x: scroll;
        flex-grow: 1;
        grid-auto-flow: column;
        gap: 20px;
        margin: 50px 0;
        padding: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-template-rows: 1fr;
    }

    .cards--choice {
        grid-template-rows: 1fr;
        justify-content: center;
    }

.card-with-cta {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}
    .card-with-cta--purchased {
        position: relative;
        cursor: default;
        opacity: 0.5;
    }
    .card-with-cta--unbuyable {
        .card {
            cursor: default;
        }
        .card-cta {
            cursor: default;
            filter: brightness(50%);
        }
    }
.card-cta {
    font-size: 16px;
    width: 100%;
}

.card-enemy-hand-wrapper {
    width: 20px;
}

.card {
    .cards & {
        display: inline-block;
        vertical-align: top;
    }
    z-index: 1;
    margin: 15px;
    width: 150px;
    aspect-ratio: 3/5;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    &:hover {
        z-index: 10;
    }
    .hand--uninterruptable & {
        cursor: initial;
    }
}
    .card--shop {
        margin: 20px auto;
    }
    .card--unplayable {
        cursor: default;
        & + .card-cta {
            cursor: default;
        }
    }
.card-inner {
    border: 1px solid black;
    box-sizing: border-box;
    padding: 5px;
    height: 100%;
    border-radius: 10px;
    color: black;
    display: flex;
    flex-direction: column;
    .card--red & {
        background: rgba(255, 0, 0, 0.2) ;
    }
    .card--green & {
        background: rgba(0, 255, 0, 0.2);
    }
    .card--blue & {
        background: rgba(0, 0, 255, 0.2);
    }
    .card--mix-red-green & {
        background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(0, 255, 0, 0.2), rgba(255, 0, 0, 0.2));
    }
    .card--mix-red-blue & {
        background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(0, 0, 255, 0.2), rgba(255, 0, 0, 0.2));
    }
    .card--mix-green-blue & {
        background: repeating-linear-gradient(45deg, rgba(0, 0, 255, 0.2), rgba(0, 255, 0, 0.2), rgba(0, 0, 255, 0.2));
    }
    .card--mix-red-green-blue & {
        background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(0, 255, 0, 0.2), rgba(0, 0, 255, 0.2));
    }

    .card--unplayable &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, 0.5);
        box-sizing: border-box;
        border-radius: 10px;
        top: 0;
        left: 0;
    }
}
.card--enemy-hand {
    transform: scale(0.2);
    transform-origin: top;
    cursor: auto;
}
.card--hand {
    position: absolute;
}
.card--overlay {
    // height: 50%;
    // width: auto;
    padding: 5px;
    margin: auto;
    width: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.card--small {
    width: 100px;
    padding: 3px;
    margin: 8px;
    border-radius: 5px;
    font-size: 12px;
}
    .card__title {
        text-align: center;
        width: 100%;
        display: block;
        margin: 10px 0 10px;
        position: absolute;
        color: white;
        text-shadow: 1px 1px 10px black;
        top: 0;
        left: 0;
    }
    .card__mana-wrapper {
        position: absolute;
        left: -10px;
        top: 30px;
    }
    .card__mana {
        border: 1px solid black;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        display: block;
        text-align: center;
        background: white;
        margin-bottom: 3px;
        color: white;
    }
    .card__mana--red {
        background-color: red;
    }
    .card__mana--blue {
        background-color: blue;
    }
    .card__mana--green {
        background-color: green;
    }
    .card__image-wrapper {
        margin-bottom: 10px;
        aspect-ratio: 1;
        overflow: hidden;
        display: grid;
    }
        .card__image {
            display: grid;
            grid-area: 1/1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    .card__description {
        flex-grow: 1;
    }
    .card__keyword {
        color: purple;
    }
    .card__keyword-descriptions {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translate(100%, -50%);
        display: none;
        filter: drop-shadow(0px 0px 15px black);
        .card:hover & {
            display: block;
        }
    }
        .card__keyword-description {
            padding: 5px;
            background: lightgrey;
            z-index: 10;
        }
    .card__levels {

    }
        .card__level-label {
            margin: 0;
            font-size: 12px;
            display: inline-block;
        }
        .card__level {
            width: 20px;
            height: 5px;
            border-radius: 5px;
            border: 1px solid black;
            display: inline-block;
        }
            .card__level--full {
                background: red;
            }
    .card__locked {
        position: absolute;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        svg {
            filter: brightness(0.5);
        }
        span {
            color: white;
            padding: 20px 0;
        }
    }

.card-front {
    background-image: url('/template/images/tmp/card.png');
    background-color: rgba(255,255,255,0.5);
    background-size: cover;
    background-blend-mode: lighten;
    backface-visibility: hidden;
    border-radius: 10px;
    height: 100%;
}

.card-back {
    height: 100%;
    width: 100%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    border: 1px solid black;
    border-radius: 10px;
    position: absolute;
    background-image: url('/template/images/tmp/card.png');
    background-size: cover;
    top: 0;
    .card--enemy-hand & {
        transform: rotateY(0deg);
    }
}

@keyframes playCard {
    0% {
        transform: scale(0.2);
        background: none;
    }
    50% {
        transform: translateX(500px) scale(1);
    }
    80% {
        transform: translateX(500px) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(500px) scale(1);
        opacity: 0;
    }
}

@keyframes rotateCardFrontFace {
    0% {
        transform: rotateY(180deg);
    }
    80% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes rotateCardBackFace {
    0% {
        transform: rotateY(0deg);
    }
    80% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.card--about-to-play {
    animation-name: playCard;
    animation-duration: 1s;
    & .card-front {
        animation-name: rotateCardFrontFace;
        animation-duration: 1s;
    }
    & .card-back {
        animation-name: rotateCardBackFace;
        animation-duration: 1s;
    }
}

.card--about-to-play--slow {
    animation-duration: 1.5s;
    & .card-front {
        animation-duration: 1.5s;
    }
    & .card-back {
        animation-duration: 1.5s;
    }
}

.card--about-to-play--normal {
    animation-duration: 1s;
    & .card-front {
        animation-duration: 1s;
    }
    & .card-back {
        animation-duration: 1s;
    }
}

.card--about-to-play--fast {
    animation-duration: .75s;
    & .card-front {
        animation-duration: .75s;
    }
    & .card-back {
        animation-duration: .75s;
    }
}

.card--about-to-play--vfast {
    animation-duration: .5s;
    & .card-front {
        animation-duration: .5s;
    }
    & .card-back {
        animation-duration: .5s;
    }
}

.card__rarity {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    --gold:   repeating-conic-gradient(#edc800 0deg, #ffe800 15deg, #f3cf00 30deg, #e3b600 45deg, #edc800 60deg) 10% 10%;
    --silver: repeating-conic-gradient(#c3c3c3 0deg, #cccccc 15deg, #c6c6c6 30deg, #d7d7d7 45deg, #c3c3c3 60deg) 10% 10%;
    --bronze: repeating-conic-gradient(#d96b41 0deg, #b15a39 15deg, #b25b3a 30deg, #d26645 45deg, #d96b41 60deg) 10% 10%;
    background: none;
    box-shadow: none;
    border: 1px black;
    &.card__rarity--bronze {
        background: var(--bronze);
        box-shadow: 0 0 5px #b15a39, 0 0 5px #b15a39;
    }
    &.card__rarity--silver {
        background: var(--silver);
        box-shadow: 0 0 5px #c3c3c3, 0 0 5px #c3c3c3;
    }
    &.card__rarity--gold {
        background: var(--gold);
        box-shadow: 0 0 5px #edc800, 0 0 5px #edc800;
    }
}

.card__glare {

}

.card__shine {
    display: grid;
    grid-area: 1/1;
    --angle: -22deg;
    --space: 5%;
    --pointer-from-center: 0.7;
    --pointer-x: 10px;
    --pointer-y: 10px;
    --card-aspect: 1;
    --card-radius: 10px;
    background-image: repeating-linear-gradient( var(--angle),
    hsla(283, 49%, 60%, 0.75) calc(var(--space)*1),
    hsla(2, 74%, 59%, 0.75) calc(var(--space)*2),
    hsla(53, 67%, 53%, 0.75) calc(var(--space)*3),
    hsla(93, 56%, 52%, 0.75) calc(var(--space)*4),
    hsla(176, 38%, 50%, 0.75) calc(var(--space)*5),
    hsla(228, 100%, 77%, 0.75) calc(var(--space)*6),
    hsla(283, 49%, 61%, 0.75) calc(var(--space)*7)
  );
  filter: brightness(calc((var(--pointer-from-center)*0.3) + 0.5)) contrast(2.3) saturate(1);
  background-blend-mode: color-dodge;
  z-index: 3;
  width: 100%;
  grid-area: 1/1;
  aspect-ratio: var(--card-aspect);
  border-radius: var(--card-radius);
  mix-blend-mode: color-dodge;
  &:after {
    content: "";
    background-image: radial-gradient( farthest-corner ellipse at calc( ((var(--pointer-x)) * 0.5) + 25% ) calc( ((var(--pointer-y)) * 0.5) + 25% ), hsl(0, 0%, 100%) 5%, hsla(300, 100%, 11%, 0.6) 40%, hsl(0, 0%, 22%) 120% );
    background-position: center center;
    background-size: 400% 500%;
    filter: brightness(calc((var(--pointer-from-center)*0.2) + 0.4)) contrast(.85) saturate(1.1);
    mix-blend-mode: hard-light;
  }
}

.card__glare {
    display: grid;
    grid-area: 1/1;
    --card-aspect: 1;
    --card-radius: 10px;
    --card-opacity: 1;
    --pointer-x: 10px;
    --pointer-y: 10px;
    background-image: radial-gradient( farthest-corner circle at var(--pointer-x) var(--pointer-y), hsla(0, 0%, 100%, 1) 10%, hsla(0, 0%, 100%, 0.6) 35%, hsla(180, 11%, 35%, 1) 60% );
    mix-blend-mode: soft-light;
    opacity: calc( var(--card-opacity) * .8 );
    filter: brightness(0.8) contrast(1.5);
    width: 100%;
    grid-area: 1/1;
    aspect-ratio: var(--card-aspect);
    border-radius: var(--card-radius);
}
