@keyframes hurt {
    0% {
        transform: translate(0, 0);
        filter: none;
    }
    50% {
        transform: translate(50px, 0);
        filter: invert(28%) sepia(100%) hue-rotate(-44deg) saturate(3);
    }
    100% {
        transform: translate(0, 0);
        filter: none;
    }
}

@keyframes enemy-hurt {
    0% {
        transform: translate(0, 0);
        filter: none;
    }
    50% {
        transform: translate(-50px, 0);
        filter: invert(28%) sepia(100%) hue-rotate(-44deg) saturate(3);
    }
    100% {
        transform: translate(0, 0);
        filter: none;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes number {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes gentle-pulse {
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.90);
    }
}

