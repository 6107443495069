@use "sass:color";

@font-face {
    font-family: 'Balgruf';
    src: url('../../fonts/Balgruf-JRye7.ttf') format('truetype')
}

// Body
$body-bg: #f5f8fa;
$body-bg-alt: #eeeeee;
// Typography
$f-sans-serif: 'Balgruf', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$c-overlay-background: #fff;
$c-overlay-color: #000;
