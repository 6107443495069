$breakpoints: (
    desktop: "min-width: 1000px",
    tablet: "max-width: 999px",
    mobile: "max-width: 600px"
) !default;

$sizes: (
    l: false,
    m: tablet,
    s: mobile
) !default;

$grid-gutter-h: 30px !default;
$grid-gutter-v: 30px !default;

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-full,
        .#{$name}-three-thirds,
        .#{$name}-four-quarters,
        .#{$name}-five-fifths,
        .#{$name}-six-sixths,
        .#{$name}-ten-tenths,
        .#{$name}-twelve-twelfths {
            width: 100%;
        }
        .#{$name}-eleven-twelfths {
            width: 100% * 11 / 12;
        }
        .#{$name}-nine-tenths {
            width: 90%;
        }
        .#{$name}-five-sixths,
        .#{$name}-ten-twelfths {
            width: 100% * 5 / 6;
        }
        .#{$name}-four-fifths,
        .#{$name}-eight-tenths {
            width: 100% * 4 / 5;
        }
        .#{$name}-three-quarters,
        .#{$name}-nine-twelfths {
            width: 100% * 3 / 4;
        }
        .#{$name}-seven-tenths {
            width: 100% * 7 / 10;
        }
        .#{$name}-two-thirds,
        .#{$name}-four-sixths,
        .#{$name}-eight-twelfths {
            width: 100% * 2 / 3;
        }
        .#{$name}-three-fifths,
        .#{$name}-six-tenths {
            width: 100% * 3 / 5;
        }
        .#{$name}-seven-twelfths {
            width: 100% * 7 / 12;
        }
        .#{$name}-one-half,
        .#{$name}-two-quarters,
        .#{$name}-three-sixths,
        .#{$name}-five-tenths {
            width: 50%;
        }
        .#{$name}-five-twelfths {
            width: 100% * 5 / 12;
        }
        .#{$name}-two-fifths,
        .#{$name}-four-tenths {
            width: 100% * 2 / 5;
        }
        .#{$name}-one-third,
        .#{$name}-two-sixths,
        .#{$name}-four-twelfths {
            width: 100% * 1 / 3;
        }
        .#{$name}-three-tenths {
            width: 100% * 3 / 10;
        }
        .#{$name}-one-quarter,
        .#{$name}-three-twelfths {
            width: 100% * 1 / 4;
        }
        .#{$name}-one-fifth,
        .#{$name}-two-tenths {
            width: 100% * 1 / 5;
        }
        .#{$name}-one-sixth,
        .#{$name}-two-twelfths {
            width: 100% * 1 / 6;
        }
        .#{$name}-one-tenth {
            width: 100% * 1 / 10;
        }
        .#{$name}-one-twelfth {
            width: 100% * 1 / 12;
        }

        .#{$name}-leftcol {
            margin-bottom: $grid-gutter-v;
            float: left;
            padding: 0 20px 0 0;
            margin-right: 20px;
        }
        .#{$name}-rightcol {
            margin-bottom: $grid-gutter-v;
            float: right;
            padding: 0 0 0 20px;
            margin-left: 20px;
        }
        .#{$name}-leftcol + .#{$name}-rightcol {
            margin-left: 0px;
        }
        .#{$name}-right + .#{$name}-leftcol {
            margin-right: 0px;
        }
        .#{$name}-onecol {
            width: auto;
            float: none;
            padding: 0;
            margin: 0 0 $grid-gutter-v;
        }
    }
}

/// Grid gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @param {Number} $gutter-y vertical spacing
/// @author Brendan Patterson <brendan@d3r.com>
@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-right: -($gutter-x);
    > * {
        padding-right: $gutter-x;
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

@mixin grid-wrapper {
    @include grid-gutter;
    @include remove-white-space;
    display: block;
}

@mixin grid-item {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
}

@mixin grid-flex-item {
    display: flex;
    flex: none;
    flex-direction: column;

    // When background styling is on a child element,
    // stretch it up to fill the parent.
    > *:only-child {
        flex: 1 0 auto;
    }
    // Images should never stretch
    > img:only-child {
        flex: none;
    }
}
