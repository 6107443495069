
/// Wrap content in an appropriate media query
///
/// @parameter {list} $points The named breakpoint or breakpoints seperate by spaces
@mixin breakpoint($points) {
    $query: '';
    @each $point in $points {
        @if $query == '' and map-get($breakpoints, $point) {
            $query: $query + map-get($breakpoints, $point);
        } @else if map-get($breakpoints, $point) {
            $query: $query + '), (' + map-get($breakpoints, $point);
        }
    }
    @if $query != '' {
        @media screen and (unquote($query)) {
            @content;
        }
    } @else {
        @content;
    }
}

/// Remove white space from space charecters in html etc
/// @author Brendan Patterson <brendan@d3r.com>
@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        font-size: 16px;
    }
}

/// Remove margin-bottom from last child
/// @author Brendan Patterson <brendan@d3r.com>
@mixin flush-last-child {
    & > :last-child {
        margin-bottom: 0;
        margin-right: 0;
    }
}
